import { format, subDays } from 'date-fns'
import { aOrganization, useAtomValue } from '~/atoms'

const getCreatedFilterUrl = (statuses: string, days: number) => {
  const date = subDays(new Date(), days)
  return `/?types=lease_sale&statuses=${statuses}&created=${format(
    date,
    'yyyy-MM-dd'
  )}`
}

export default function useQuickSearchLinks() {
  const organization = useAtomValue(aOrganization)

  if (organization!!.options.appraisersOnly) {
    return [
      { label: 'Leased', url: '/?types=lease&statuses=leased' },
      { label: 'Sold', url: '/?types=sale&statuses=sold' },
      { label: 'Leased/Sold', url: '/?types=lease_sale&statuses=leased_sold' },
      {
        label: 'Latest Listings (1 day)',
        url: getCreatedFilterUrl('leased_sold', 1),
      },
      {
        label: 'Latest Listings (7 days)',
        url: getCreatedFilterUrl('leased_sold', 7),
      },
      {
        label: 'Latest Listings (30 days)',
        url: getCreatedFilterUrl('leased_sold', 30),
      },
    ]
  }

  return [
    { label: 'For Lease', url: '/?types=lease&statuses=active' },
    { label: 'For Sale', url: '/?types=sale&statuses=active' },
    { label: 'For Sale/Lease', url: '/?types=lease_sale&statuses=active' },
    { label: 'Latest Listings (1 day)', url: getCreatedFilterUrl('active', 1) },
    {
      label: 'Latest Listings (7 days)',
      url: getCreatedFilterUrl('active', 7),
    },
    {
      label: 'Latest Listings (30 days)',
      url: getCreatedFilterUrl('active', 30),
    },
  ]
}
